//type
import {
    VendeeInput,
    GetOfferMutation,
    ROOF_MATERIAL,
    DAYTIME_CONSUMPTION,
    BUILDING_TYPE,
    COUNTRY_CODE
} from '@components/lib/interfaces/config/graphql/API';
import { State } from '@components/store/interfaces/slices/simulator/state';
import { SendGTagEvent } from '@components/lib/interfaces/helper/googleAnalytics';

//library
import { Client } from 'aws-amplify/api';
import { createAsyncThunk } from '@reduxjs/toolkit';

//code
import getOfferMutation from '@components/store/slices/simulator/actions/query/getOfferMutation';

const getOffer = createAsyncThunk<
    GetOfferMutation['getOffer'] | null,
    {
        clientApi: Client;
        countryCode: COUNTRY_CODE;
        sendGtagEventRsl: SendGTagEvent;
    },
    { state: { simulator: State } }
>(
    'simulator/getOffer',

    async ({ clientApi, countryCode }, thunkApi) => {
        const { quote, utm } = thunkApi.getState().simulator;
        const email = quote.contact?.email || '';
        const phone = quote.contact?.phone || '';

        const input: VendeeInput = {
            name: quote.contact?.name || '',
            family_name: quote.contact?.family_name || '',
            email,
            address: quote.address || '',
            address_components: quote.address_components || [],
            company: quote.contact?.company || '',
            phone,
            timeframe: quote.timeframe || '1',
            referral: quote.contact?.referral || '',
            building: {
                type: quote.type || BUILDING_TYPE.LANDED_HOUSE,
                energy: quote.energy || null,
                utilities: Number(quote.utilities) || null,
                lng: Number(quote.lng),
                lat: Number(quote.lat),
                material: quote.roof || ROOF_MATERIAL.OTHER,
                area: Number(quote.area),
                polygon: quote.polygon || [],
                retailer: quote.retailer,
                daytime_consumption: quote.dayTimeConsumption || DAYTIME_CONSUMPTION.OTHER,
                available: Number(quote.available),
                osm_id: quote.osm_id,
                height: quote.height,
                storeys: quote.storeys,
                meter_box: quote.meter_box
            },
            utm
        };

        const response = await getOfferMutation(clientApi, { country_code: countryCode, input });

        // Send google analytic event after user finishes simulator
        if (response?.code && email && phone) {
            window.dataLayer.push({
                event: 'submit_simulation',
                lead_id: response.code,
                user_email: email,
                user_phone_number: phone
            });
        }

        return response;
    }
);

export default getOffer;
